import { withTranslation, Trans } from 'react-i18next';
import React from "react";
import { Popup, MainImage } from '..';


const RecyclingPopup = (props) => {
    return (
        <Popup show={props.show} onHide={props.onHide}>
            <div className='center flex-column'>
                <MainImage img='Home/recycle.png' height='' />
                <div className='fs30 fw600 text-center my-5 py-5'><Trans i18nKey="recycling_popup.title" />🌱<br /><Trans i18nKey="recycling_popup.subtitle" /></div>
                {/* <ActionButton width='35vw' className='my-4' onClick={() => { setShowRecycling(false); }} to='#'><Trans i18nKey="home.recycling_button" /></ActionButton> */}
            </div>
        </Popup>
    );
}

export default withTranslation()(RecyclingPopup);