import { withTranslation, Trans } from 'react-i18next';
import React, { useContext } from "react";
import { AppContext } from '../../utils/context';
import { Popup } from '../../components';
import styled from "styled-components";


const Button = styled.div`
    min-width: ${props => props.width};
    max-width 420px;
    height: 12.5vh;
    max-height: 130px;
    padding: 0 3vw;
    background: ${props => !props.secondary ? ({ theme: { primaryButton } }) => (!props.disabled ? primaryButton.bg : primaryButton.disabled) : ({ theme: { secondaryButton } }) => secondaryButton.bg};
    color: ${props => !props.secondary ? ({ theme: { primaryButton } }) => primaryButton.clr : ({ theme: { secondaryButton } }) => secondaryButton.clr};
    border-radius: ${({ theme: { primaryButton } }) => primaryButton.radius};
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 2.8rem;
    border:${props => !props.secondary ? 'none' : '2px solid #F2F2F2'};
    &:active {
        box-shadow: none;
    }
    display:flex;
    justify-content: center;
    align-items:center;
`;


const IdlePopup = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;

    return (
        <Popup show={props.show} onHide={props.onHide} >
            <div className='d-flex flex-column justify-content-center align-items-center my-5 pb-5'>
                <div className='fs30 fw600'><Trans i18nKey="idle_popup.title" /></div>
                <div style={{ width: '52vw' }} className='text-center fs30 lh48 my-5'><Trans i18nKey="idle_popup.subtitle1" /><b>{props.counter}”</b><Trans i18nKey="idle_popup.subtitle2" /></div>
                <Button className='mt-5' onClick={props.onHide} width='27vw'><Trans i18nKey="idle_popup.yes" /></Button>
            </div>
        </Popup>
    );
}

export default withTranslation()(IdlePopup);