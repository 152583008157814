import React from "react";
import styled from "styled-components";

const Card = styled.div`
    position: relative;
    width: 90vw;
    height: ${props => !props.height ? (!props.large ? '48vh' : '65vh') : props.height};
    background: #FFFFFF;
    color: ${({ theme: { colors } }) => colors.fonts};
    border-radius: 3rem;
    display: flex;
`;

const MainCard = (props) => {
    return (
        <Card className={props.className}
            style={props.style}
            large={props.large}
            height={props.height}
        >
            {props.children}
            {props.notification}
        </Card >
    );
}

export default MainCard;