import React from "react";
import styled from "styled-components";


const Border = styled.div`
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
  border: 4px solid ${({ theme: { colors } }) => colors.primary};
`;
const Fill = styled.div`
  width: fit-content;
  height: fit-content;
  border-radius: 18px;
  border: 20px solid #F2F2F2;
`;

const BorderQRCode = (props) => {
    return (
        <Border>
            <Fill>
                <div style={{ width: 'fit-content', border: '10px solid #FFFFFF' }}>
                    {props.children}
                </div>
            </Fill>
        </Border>
    );
}

export default BorderQRCode;