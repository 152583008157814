import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainVideo, MainCard, MainTitle } from '../../components';

const InsertComplete = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'insert-complete' })
    }, [])

    return (
        <>
            <MainLayout
                exit
                progress_bar
                nextButton={{
                    text: <Trans i18nKey="next_button" />,
                    to: process.env.PUBLIC_URL + '/close-drawer-complete',
                }}
                backButton={{ to: state.check.sim ? process.env.PUBLIC_URL + '/timer/insert-sim' : process.env.PUBLIC_URL + '/timer/fmi-info' }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='align-items-center justify-content-center pt-5'>
                        <MainVideo style={{ marginRight: '5vw' }} video='Insert/insert.mp4' />
                        <MainTitle style={{ width: '50vw' }}><Trans i18nKey="insert_complete" /></MainTitle>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
        </>
    );
}

export default withTranslation()(InsertComplete);