import { withTranslation, Trans } from 'react-i18next';
import React from "react";
import { MainImage } from '../../components';


const TutoralSlide = (props) => {
    return (
        <div style={{ height: '100%' }} className='d-flex flex-column justify-content-between'>
            <div style={{ marginBottom: '12vh' }} className='fontThicker fs72 lh72 fw800'><Trans i18nKey="tutorial.how_it_works" /></div>
            <div className='d-flex align-items-center px-4'>
                <MainImage style={{ marginRight: '6vw' }} img={`Tutorial/tut${props.img}.png`} height='20vh' />
                <div>
                    <div className='fs38 fw600 mb-4'>{props.title}</div>
                    <div style={{ width: '46vw' }} className='fs20 lh32'>{props.subtitle}</div>
                </div>
            </div>
        </div>
    );
}
export default withTranslation()(TutoralSlide);