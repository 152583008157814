import { withTranslation, Trans } from 'react-i18next';
import React from "react";
import { MainImage } from '../../components';
import { Modal } from 'react-bootstrap';


const NetworkErrorPopup = (props) => {
    return (
        <Modal className={props.className} show={props.show} onHide={props.onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className='d-flex flex-column justify-content-center align-items-center text-center my-5 py-5'>
                    <MainImage className='mt-5' img='NetworkErrorPopup/network_error_popup.png' height='' />
                    <div className='fs30 my-5 fw600'><Trans i18nKey="network_error_popup.title" /></div>
                    <div style={{ width: '40vw' }} className='fs30 mb-5 pb-5'><Trans i18nKey="network_error_popup.subtitle" /></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default withTranslation()(NetworkErrorPopup);