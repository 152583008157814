import { withTranslation, Trans } from 'react-i18next';
import React from "react";
import { Popup } from '../../components';
import styled from "styled-components";

const Scrollbar = styled.div`
height: 65vh;
overflow-y: scroll;
font-size: 1.6rem;
font-weight: 500;
&::-webkit-scrollbar {
    width: 4px;
  }
   
  &::-webkit-scrollbar-track {
    background-color: #E6E6E6;
  }
   
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme: { colors } }) => colors.primary};
  }
`;

const PrivacyPolicyOrangepl = (props) => {
    return (
        <Popup show={props.show} onHide={props.onHide}>
            <div style={{ marginTop: '-5vh' }} className='center flex-column px-5 mx-5'>
                <div className='fs34 fw600 mb-5'>Regulamin akcji</div>
                <Scrollbar>
                    <div className='mx-5 px-5' style={{ width: '61vw' }}>
                        <div className='text-center mb-3'><b>§ 1 - Definicje</b></div>
                        <div className='mb-5'>
                            1. <b>Akcja</b>  –   akcja   wyceny   i   odkupu   Urządzeń   organizowana   i   prowadzona   na
                            zasadach opisanych w niniejszym regulaminie w okresie od [__] czerwca 2022
                            roku, do 31 grudnia 2022 roku.<br />
                            2. <b>Organizator</b> – Orange Polska spółka akcyjne z siedzibą w Warszawie (02-326),
                            przy Al. Jerozolimskich 160, wpisana do  rejestru przedsiębiorców prowadzonego
                            przez Sąd Rejonowy dla m. st. Warszawy w  Warszawie, XII Wydział Gospodarczy
                            Krajowego Rejestru Sądowego pod numerem KRS 0000010681, kapitał zakładowy
                            3.937.072.437 PLN, NIP 526-02-50-995, REGON  012100784.<br />
                            3. <b>Urządzenie</b> – smartfon będący własnością Klienta, zakupiony przez niego przed
                            dniem [__] czerwca 2022 r., nieobciążony prawem osób trzecich, w pełni sprawny,
                            bez futerału, folii lub szkiełka ochronnego, nieposiadający żadnych danych ponad
                            te,   jakie   znajdują   się   w   urządzeniu   tego   typu   po   przywróceniu   ustawień
                            fabrycznych; w przypadku Klientów posiadających urządzenie z zastrzeżonym
                            prawem własności na rzecz Orange Polska lub innego operatora, warunkiem
                            skorzystania z Oferty jest wykonanie zobowiązania Klienta, które jest warunkiem
                            przeniesienia na niego prawa własności (np. spłata wszystkich rat); dla uniknięcia
                            wątpliwości precyzuje się, że smartfonem jest telefon komórkowy posiadający
                            ekran dotykowy oraz umożliwiający bezpośrednio przez sieć GSM prowadzenie
                            rozmów, wysyłanie wiadomości oraz transmisję danych (Internet).<br />
                            4. <b>Klient</b>  – pełnoletnia osoba fizyczna, osoba fizyczna prowadząca jednoosobową
                            działalność gospodarczą lub osoba prawna posiadająca miejsce zamieszkania lub
                            siedzibę na terytorium Rzeczpospolitej Polskiej, która zapoznała się z treścią
                            niniejszego   Regulaminu   i   zaakceptowała   jego   postanowienia   oraz   spełniła
                            wszystkie warunki w nim określone.<br />
                            5. <b>GP</b>  –   Green   Panda   Single   Member   Private   Company   (grecka   jednoosobowa
                            działalność gospodarcza), z siedzibą pod adresem: 20, Charitos Str., GR 10675,
                            Ateny,   wpisaną   do   greckiego   rejestru   handlowego   pod   numerem   wpisu
                            150229702000, numer rejestracji podatkowej 801149156.<br />
                            6. <b>Regulamin</b> – niniejszy Regulamin Promocji określający warunki uczestnictwa w
                            Akcji, czas trwania Akcji, sposób informowania o Akcji i jej warunkach oraz sposób
                            składania reklamacji związanych z Akcją.<br />
                            7. <b>Odkup</b> – usługa oferowana przez GP, umożliwiająca Klientom sprzedaż Urządzeń
                            będących własnością Klienta, na warunkach określonych wyłącznie przez GP, z
                            której   Klient   może   skorzystać   przy   spełnieniu   warunków   określonych   w
                            niniejszym Regulaminie.<br />
                            8. <b>Voucher</b> – kupon wydawany Klientowi przez Kiosk, który Klient może zrealizować
                            wyłącznie w salonie Organizatora. Wartość kuponu jest uzależniona od wysokości
                            wyceny   Urządzenia   dokonanej   przez   Kiosk.   Klient   może   dokonać   płatności
                            kuponem   w   całości   lub   w   części   za   wybrany   towar   dostępny   w   salonie
                            Organizatora. Kupon jest klientowi dostarczy w formie elektronicznej (e-mail lub
                            sms) lub drukowany przez Kiosk.<br />
                            9. <b>Kiosk</b>  –   maszyna   dostarczana   do   salonu   Organizatora   przez   GP   w   celu
                            dokonywania   wycen   Urządzeń   oraz   drukowania   Voucherów.   Kiosk   dokonuje
                            wyceny   Urządzenia   na   podstawie   autorskiego   oprogramowania   i   katalogu
                            cennikowego GP.<br />
                            10.<b>Oświadczenie</b> – Oświadczenie Klienta o posiadaniu pełni praw do Urządzenia,
                            którego wzór stanowi Załącznik nr 2 do Regulaminu.<br />
                            11.<b>Strona Internetowa </b>  –  strona   Akcji   znajdująca   się   pod   adresem
                            https://www.orange.pl/zobacz/program-re<br />
                        </div>
                        <div className='text-center mb-3'><b>§ 2 - Postanowienia ogólne</b></div>
                        <div className='mb-5'>
                            1. W   ramach   Akcji   Klienci,   którzy   spełnili   warunki   określone   w   niniejszym
                            Regulaminie, otrzymają Vouchery umożliwiające Klientom dokonania zakupów w
                            salonie Organizatora.<br />
                            2. Akcja trwa od dnia [__] czerwca 2022 roku, do 31 grudnia 2022 roku i jest
                            dostępna   w   salonach   Organizatora,   które   zostaną   wskazane   na   Stronie
                            Internetowej.<br />
                            3. W Promocji mogą wziąć udział Klienci, którzy: <br />
                            <div className='ms-4'>
                                a. są jednymi właścicielami Urządzenia i posiadają pełnie praw własności do
                                Urządzenia, ponadto Urządzenie jest wolne od obciążeń osób trzecich, nie są
                                obciążone programami ratalnymi Organizatora lub innych operatorów;<br />
                            </div>
                            4. W celu dokonania wyceny Urządzenia przez Kiosk, niezbędne jest uruchomienie
                            Urządzenia wraz z aktywną kartą SIM GP, którą Klient otrzyma od pracownika
                            salonu   Organizatora   oraz   posiadanie   dostępu   do   sieci   Internet.   Wycena
                            Urządzenia jest bezpłatna.<br />
                            5. Wartość   Vouchera   otrzymanego   przez   Klienta   jest   równa   wartości   wyceny
                            dokonanej przez Kiosk.<br />
                            6. Organizatorowi przysługuje w szczególności prawo zażądania okazania oryginału
                            dowodu nabycia dokumentującego zakup Urządzenia lub dodatkowej weryfikacji,
                            jak również potwierdzania faktu zakupu w Punkcie handlowym, w szczególności w
                            celu weryfikacji daty zakupu Urządzenia jak również w celu weryfikacji czy Klient
                            jest   właścicielem   Urządzenia   oraz   czy   posiada   pełnie   praw   własności   do
                            Urządzenia. <br />
                            7. O   zakończeniu   Akcji   Organizator   poinformuje   niezwłocznie   na   Stronie
                            Internetowej.<br />
                        </div>
                        <div className='text-center mb-3'><b>§ 3 - Ogólne zasady korzystania z Akcji</b></div>
                        <div className='mb-5'>
                            1. Warunkiem otrzymania Vouchera umożliwiającego Klientowi dokonania płatności
                            w całości lub w części za zakupy w salonie Organizatora, jest akceptacja wyceny
                            dokonanej przez Kiosk.<br />
                            2. Klient w czasie trwania Promocji może otrzymać i skorzystać z wielu Voucherów,
                            niezależnie od liczby wycenionych Urządzeń.<br />
                            3. Organizator zastrzega, iż Vouchera nie można wymienić na ekwiwalent pieniężny.<br />
                            4. W   przypadku   osób   fizycznych   biorących   udział   w   Akcji   w   charakterze
                            konsumentów   wartość   świadczeń   wchodzących   w   skład   Akcji,   stanowiących
                            wartość Vouchera w Akcji, jest zwolniona od podatku dochodowego od osób
                            fizycznych na podstawie art. 21 ust. 1 pkt 68 ustawy z dnia 26 lipca 1991 r. o
                            podatku dochodowym od osób fizycznych (t.j. Dz. U. z 2016 r., poz. 2032 ze zm.).<br />
                            5. Organizator   zastrzega,   że   w   przypadku   awarii   oprogramowania
                            wykorzystywanego do drukowania Voucherów i/lub wysyłania ich do klienta drogą
                            elektroniczną, która uniemożliwiałaby wydrukowanie i/lub wysłanie do Klienta
                            Vouchera zgodnie z zasadami określonymi w niniejszym Regulaminie, będzie
                            kontaktował   się   bezpośrednio   z   Klientami   w   celu   wydania   Voucherów   w
                            uzgodniony z Klientem sposób.<br />
                        </div>
                        <div className='text-center mb-3'><b>§ 4 - Realizacja Vouchera i wycena Urządzenia</b></div>
                        <div className='mb-5'>
                            1. Klient może zrealizować Voucher w ramach dokonywania zakupów w salonie
                            Organizatora.<br />
                            2. Voucher ważny jest do dnia [__] 2022 roku. Po upływie wskazanego terminu,
                            wykorzystanie Vouchera nie będzie możliwe.<br />
                            3. Realizacja Vouchera w innym salonie, niż salony Organizatora nie jest możliwa.<br />
                            4. Umowa sprzedaży zawierana jest pomiędzy GP a Klientem. Cena Urządzenia w
                            ramach wyceny ustalana jest wyłącznie przez GP w oparciu o przyjęty przez niego
                            cennik, z uwzględnieniem modelu, wieku oraz stanu urządzenia.<br />
                            5. Organizator nie ponosi odpowiedzialności za wysokość wyceny oraz za działanie
                            Kiosków.   Całość   odpowiedzialność   za   wysokość   wyceny,   jak   również   za
                            działalność Kiosków ponosi GP.<br />
                            6. Przed rozpoczęciem procesu wyceny Klient jest zobowiązany:<br />
                            <div className='ms-4'>
                                a) Zabezpieczyć wszelkie dane z Urządzenia we własnym zakresie;<br />
                                b) Usunąć z Urządzenia wszelkie folie, szkła ochronne i etui;<br />
                                c) Wyjąć z Urządzenia swoją kartę SIM;<br />
                                d) Wyjąć z Urządzenia kartę pamięci (o ile Urządzenia posiada slot na kartę pamięci);<br />
                                e) Usunąć z Urządzenia wszelkie blokady tj.:<br />
                                (i) w   przypadku   Urządzeń   marki   Apple   –   wyłączyć   blokadę   „Find  my iPhone”;<br />
                                (ii) w przypadku Urządzeń z systemem Android – wylogować się z konta Google;<br />
                                f) Przywrócić Urządzenie do ustawień fabrycznych;<br />
                                g) Podpisać Oświadczenie.<br />
                            </div>
                            7. Organizator   nie   ponosi   odpowiedzialności   za   jakiekolwiek   dane   Klienta
                            pozostawione na Urządzeniu oraz za niedopełnienie przez Klienta obowiązków
                            wskazanych w §4 ust. 6 lit. a-f powyżej.<br />
                            8. Z chwilą akceptacji wyceny przez Klienta, Klient przyjmuje do wiadomości, że
                            Urządzenia staje się własnością GP, a wszelkie dane, które nie zostały przez
                            Klienta zabezpieczone, ulegają usunięciu i ich odzyskanie nie jest możliwe.<br />
                        </div>
                        <div className='text-center mb-3'><b>§ 5 - Reklamacje związane z Promocją</b></div>
                        <div className='mb-5'>
                            1. Reklamacje związane z wyceną, w tym w szczególności z brakiem akceptacji
                            wyceny,   powinny   być   składane   do   GP   i   rozpatrywane   będą   na   zasadach
                            określonych przez GP.<br />
                            2. Reklamacje związane z Akcją należy zgłaszać Organizatorowi w terminie 14 dni
                            od   dnia   otrzymania   Vouchera     lub   dnia,   w   którym   Klient   miał   możliwość
                            otrzymania Vouchera. Reklamacje mogą być składane drogą mailową na adres
                            [__].<br />
                            3. Reklamacja   powinna   zawierać   dane   umożliwiające   identyfikację   Klienta,   jak
                            również dokładny opis zdarzenia uzasadniającego złożenie reklamacji i wszelkie
                            okoliczności na poparcie zasadności żądania Klienta.<br />
                            4. Organizator   będzie   rozpatrywał   reklamacje   w   oparciu   o   postanowienia
                            niniejszego Regulaminu. Reklamacje będą rozpatrywane w terminie do 14 dni od
                            dnia   otrzymania   reklamacji   przez   Organizatora.   Klient   składający   reklamację
                            zostanie   powiadomiony   o   sposobie   rozpatrzenia   reklamacji   w   formie
                            odpowiadającej formie, w jakiej została złożona reklamacja, niezwłocznie po jej
                            rozpatrzeniu.<br />
                            5. W przypadku uwzględnienia reklamacji i przyznania Klientowi Vouchera w ramach
                            reklamacji, Klient uprawniony będzie do skorzystania z Vouchera przez okres 14
                            dni od dnia wydania Vouchera . Po upływie wskazanego powyżej terminu Voucher
                            traci ważność.<br />
                        </div>
                        <div className='text-center mb-3'><b>§ 6 - Dane osobowe</b></div>
                        <div className='mb-5'>
                            1. Administratorem   danych   osobowych   Klientów,   którzy   skorzystali   z   Akcji   jest
                            Spółka Green Panda Single Member Private Company (grecka jednoosobowa
                            działalność gospodarcza), z siedzibą pod adresem: 20, Charitos Str., GR 10675,
                            Ateny, Grecja, wpisaną do greckiego rejestru handlowego pod numerem wpisu
                            150229702000,   numer   rejestracji   podatkowej   801149156   (dalej
                            „<b>Administrator”</b>).<br />
                            2. Organizator   Akcji   działa   w   ramach   współpracy   z   GP   na   podstawie   umowy
                            powierzenia przetwarzania danych.<br />
                            3. Dane osobowe Klientów będą zbierane i przetwarzane przez Administratora w
                            zakresie niezbędnym dla organizacji i przeprowadzenia Akcji, w tym przekazania
                            Vouchera.<br />
                            4. Dane osobowe  Klientów będą przetwarzane przez Administratora  przez czas
                            trwania Akcji (podstawa prawna: zgoda), a po jej zakończeniu w okresie i dla
                            celów dochodzenia i obrony przed roszczeniami oraz na potrzeby wykazania
                            zgodności z przepisami (podstawa prawna: prawnie uzasadniony interes).
                            5. Administratorem danych osobowych Klientów, którzy skorzystali z Vouchera jest
                            Organizator.<br />
                            6. Klient ma prawo do: cofnięcia zgody w dowolnym momencie (przy czym cofnięcie
                            zgody nie będzie miało wpływu na zgodność z prawem przetwarzania danych na
                            podstawie zgody w okresie przed jej cofnięciem), dostępu do danych Klienta
                            przetwarzanych   przez   Administratora   1   i/lub   Administratora   2,   sprostowania
                            (poprawiania)   danych,   usunięcia   danych   lub   ograniczenia   ich   przetwarzania,
                            przeniesienia danych, co oznacza prawo do otrzymania przez Klienta danych
                            przetwarzanych   przez   Administratora   i/lub   Organizatora   lub   żądanie   ich
                            przeniesienia do innego podmiotu oraz wniesienia skargi do Prezesa Urzędu
                            Ochrony Danych Osobowych.<br />
                            7. Klientom przysługuje prawo zgłoszenia sprzeciwu wobec przetwarzania danych
                            przez Administratora w oparciu o podstawę prawną "prawnie uzasadnionego
                            interesu administratora”.<br />
                            8. Żądania wobec Administratora lub Organizatora, o których mowa wyżej, Klient
                            może złożyć do Administratora 1 lub Organizatora na adresy e-mail:<br />
                            <div className='ms-4'>
                                a) Administrator 1: [__]<br />
                                b) Organizator: [__]<br />
                            </div>
                            9. Podanie   danych   osobowych   jest   dobrowolne,   nie   wynika   z   obowiązku
                            ustawowego, lecz jest konieczne do udziału w Akcji. W przypadku niepodania
                            wszystkich danych wymaganych do rejestracji, nie będzie można przystąpić do
                            Akcji.<br />
                            10.Zgoda   na   przetwarzanie   danych   osobowych   stanowi   Załącznik   nr   1   do
                            Regulaminu.<br />
                        </div>
                        <div className='text-center mb-3'><b>§ 7 - Postanowienia końcowe</b></div>
                        <div className='mb-5'>
                            1. Treść niniejszego Regulaminu będzie dostępna na Stronie Internetowej.<br />
                            2. W   sprawach   nieuregulowanych   Regulaminem   zastosowanie   znajdują
                            powszechnie  obowiązujące przepisy prawa polskiego.<br />
                            3. Organizator   zastrzega   sobie   prawo   wprowadzenia   zmian   do   niniejszego
                            Regulaminu, z zastrzeżeniem, że nie naruszy to praw Klientów.<br />
                            4. O zmianie Regulaminu, Klient zostanie poinformowany przez umieszczenie przez
                            Organizatora na Stronie internetowej odpowiedniego komunikatu odsyłającego
                            do     zestawienia   wszystkich   zmian   jakie   zostały   dokonane   w   Regulaminie.
                            Informacja ta utrzymana będzie przez okres co najmniej 30 dni. Klient, który nie
                            zgodzi się z zaproponowanymi zmianami, nie będzie miał możliwości wzięcia
                            udziału w Akcji.<br />
                            5. Klient   przed   rozpoczęciem   wyceny   Urządzenia   musi   zaakceptować   zapisy
                            niniejszego Regulaminu. W przypadku braku akceptacji Klienta, o której mowa w
                            zdaniu poprzednim, proces wyceny nie zostanie rozpoczęty.<br /><br />
                            <u>Załączniki:</u><br />
                            <div className='ms-4'>
                                1. Zgoda na przetwarzanie danych osobowych Klienta;
                                2. Oświadczenie Klienta o posiadaniu pełni praw własności do Urządzenia;
                            </div>
                        </div>
                    </div>
                </Scrollbar>
            </div>
        </Popup>
    );
}

export default withTranslation()(PrivacyPolicyOrangepl);