import { withTranslation, Trans } from 'react-i18next';
import React from "react";
import { Popup, MainImage, MainTitle } from '../../components';


const QRPopup = (props) => {
    return (
        <Popup show={props.show} onHide={props.onHide}>
            <div className='d-flex justify-content-center align-items-center my-5 pb-5'>
                <MainImage style={{ marginRight: '6vw' }} img='QrPopup/qr_popup.png' height='15vh' />
                <div>
                    <MainTitle className='text-center mb-5'><Trans i18nKey="qr_popup.title" /></MainTitle>
                    <div className='d-flex align-items-center my-4'>
                        <MainImage img='QrPopup/camera.svg' height='' />
                        <div className='ms-4 fs20 lh26 fw500'><Trans i18nKey="qr_popup.step1" /></div>
                    </div>
                    <div className='d-flex align-items-center my-4'>
                        <MainImage img='QrPopup/qr_code.svg' height='' />
                        <div className='ms-4 fs20 lh26 fw500'><Trans i18nKey="qr_popup.step2" /></div>
                    </div>
                    <div className='d-flex align-items-center my-4'>
                        <MainImage img='QrPopup/link_simple.svg' height='' />
                        <div className='ms-4 fs20 lh26 fw500'><Trans i18nKey="qr_popup.step3" /></div>
                    </div>
                </div>
            </div>
        </Popup>
    );
}

export default withTranslation()(QRPopup);