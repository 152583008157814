import React from "react";
import styled from "styled-components"
import PromoCodeIcon from './promo_code.svg';

const Bg = styled.div`
    background: #E7FDED;
    color: #0FBD3B;
    width: fit-content;
    border-radius: 8px;
    font-size: 2rem;
    line-height: 4.8rem;
    padding: 0 20px;
`;

const PromoCode = (props) => {
    return (
        <Bg className={props.className}><img className="me-3" src={PromoCodeIcon} alt='promo_code' />{props.children}</Bg>
    );
}
export default PromoCode;