import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainImage } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const CheckBox = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;

    const [checked, setChecked] = useState(false)
    const handleClick = () => {
        setChecked(!checked)
    }
    useEffect(() => {
        var check = {};
        check[props.field] = checked;
        dispatch({
            type: "checkbox",
            payload: check
        })
    }, [checked])

    return (<>
        <div onClick={() => handleClick()}>
            {!checked && <MainImage img='UserInformation/checkbox.svg' height='13vh' />}
            {checked && <FontAwesomeIcon className='fs30' icon={faCheck} />}
        </div>
    </>
    );
}

export default CheckBox;