import React from "react";
import styled from "styled-components"


const IconWrapper = styled.div`
  svg {
    width: ${props => props.width};
    height: ${props => props.height};
    fill:${props => props.fill};
    path.clr{
        fill: ${props => props.clr};
    }
    path.clr{
        stroke: ${props => props.clr};
    }
    path.bg{
        fill: ${props => props.bg};
    }
  }
`;

const Icons = (props) => {

    return (

        <IconWrapper
            width={props.width}
            height={props.height}
            fill={props.fill}
            bg={props.bg}
            clr={props.clr}
            className={props.className}
        >
            {props.children}
        </IconWrapper>
    );
}

export default Icons;