import React from "react";
import styled from "styled-components";
import InfoIcon from './images/info_icon.svg'

const Button = styled.div`
    width: fit-content;
    height: ${props => !props.height ? '6.2vh' : props.height};
    max-height: 100px;
    padding: 0 2vw;
    color: ${({ theme: { colors } }) => colors.fonts};
    border-radius: ${({ theme: { primaryButton } }) => primaryButton.radius};
    font-size: 2rem;
    font-weight: 400;
    line-height: 4.5rem;
    text-decoration:none;
    border: 2px solid #F2F2F2;
    &:hover,focus {
        color: ${({ theme: { colors } }) => colors.fonts};
    }
    display: flex;
    justify-content: center;
    align-items: center;

`;

const InfoButton = (props) => {
    const { t, i18n } = props;

    return (
        <Button className={props.className}
            onClick={props.onClick}
            height={props.height}
        >
            {props.no_icon ? '' : <img src={props.icon ? props.icon : InfoIcon} alt="info_icon" width="32" />}
            <div className='ms-4'>{props.children}</div>
        </Button>

    );
}

export default InfoButton;