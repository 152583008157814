import React from "react";
import styled from "styled-components"

const Title = styled.div`
    font-size: 3.8rem;
    font-weight: 600;
    line-height: 4.8rem;
`;

const MainTitle = (props) => {
    return (
        <Title style={props.style} className={props.className}>{props.children}</Title>
    );
}
export default MainTitle;