import React from "react";
import { ActionButton, Icons } from '..';
import { ReactComponent as NextArrow } from './next_arrow.svg';


const NextButton = (props) => {
    return (
        <ActionButton
            width='26vw'
            to={!props.onClick ? (!props.disabled ? props.to || "/" : "#") : '#'}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            <span>{props.children}</span>
            <span className="ms-4 d-inline"><Icons width='3vw' clr={({ theme: { primaryButton } }) => primaryButton.clr} > <NextArrow /></Icons></span>
        </ActionButton>
    );
}

export default NextButton;