import React from "react";
import styled from "styled-components"

const SubTitle = styled.div`
    font-size: 3rem;
    font-weight: 400;
    line-height: 4.5rem;
    color: #808080;
`;

const MainSubtitle = (props) => {
    return (
        <SubTitle className={props.className}>{props.children}</SubTitle>
    );
}
export default MainSubtitle;