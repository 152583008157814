import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { ActionButton, Input, MainTitle, Popup, MainImage, PrivacyPolicy } from '../../components';

const EmailPopup = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [emailValue, setEmailValue] = useState();
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const email = state.userInformation.email ? state.userInformation.email : '';

    useEffect(() => {
        if (state.userInformation.email) {
            if (/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(state.userInformation.email))
                setEmailValue(true);
            else
                setEmailValue(false);
        }
    }, [state.userInformation.email]);

    return (
        <>
            <Popup show={props.show} onHide={props.onHide}>
                <div className='d-flex flex-column align-items-center'>
                    <MainTitle className='text-center mt-4'> <Trans i18nKey="email.title" /></MainTitle>
                    <div className='d-flex flex-column my-5 pb-5'>
                        <div className='fs24 lh32 fw600 mb-3'> <Trans i18nKey="email.subtitle" /></div>
                        <Input field="email" />
                        <div className='fs16 lh26 mt-3'><Trans i18nKey="email.personal information" /><u><b onClick={() => setShowPrivacyPolicy(true)}><Trans i18nKey="email.privacy policy" /></b></u></div>
                    </div>
                    <div className='d-flex'>
                        <ActionButton disabled={!emailValue} className='mx-4' onClick={props.submit} to='#' width='27vw'><Trans i18nKey="email.done" /></ActionButton>
                    </div>
                </div>
            </Popup>
            <Popup show={props.success} onHide={props.onHideSuccess}>
                <div className='d-flex flex-column align-items-center pb-5'>
                    <MainImage img='EmailPopup/check_circle.png' height='10vh' />
                    <div style={{ width: '40vw' }} className='fs30 fw600 text-center my-5'><Trans i18nKey="email.reminder" /> {email}!</div>
                </div>
            </Popup>
            <PrivacyPolicy show={showPrivacyPolicy} onHide={() => setShowPrivacyPolicy(false)} />
        </>
    );
}

export default withTranslation()(EmailPopup);