import { withTranslation, Trans } from 'react-i18next';
import React from "react";
import { Popup, MainImage } from '../../components';


const FmiPopup = (props) => {
    return (
        <Popup show={props.show} onHide={props.onHide}>
            <div className='d-flex flex-column justify-content-center align-items-center pb-5'>
                <div className='fs30 fw600 mb-5 pb-4'><Trans i18nKey="fmi_popup.title" /></div>
                <div className='center'>
                    <MainImage style={{ marginRight: '5vw' }} img='FmiPopup/steps.png' />
                    <div className='d-flex flex-column align-items-start'>
                        <div className='center'>
                            <MainImage className='' img='FmiPopup/step1.png' />
                            <div className='ms-5 fs24 lh40 fw500'><Trans i18nKey="fmi_popup.li1" /></div>
                        </div>
                        <div className='center my-5 py-5'>
                            <MainImage className='my-' img='FmiPopup/step2.png' />
                            <div className='ms-5 fs24 lh40 fw500'><Trans i18nKey="fmi_popup.li2" /></div>
                        </div>
                        <div className='center'>
                            <MainImage className='' img='FmiPopup/step3.png' />
                            <div className='ms-5 fs24 lh40 fw500'><Trans i18nKey="fmi_popup.li3" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    );
}

export default withTranslation()(FmiPopup);